import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Footer from './Footer';

class App extends React.Component {
	state = {
		text: '',
		inputValue: '',
		lastLetter: '',
		words: [],
		completedWords: [],
		completed: false,
		startTime: undefined,
		timeElapsed: 0,
		wpm: 0.00,
		started: false,
		progress: 0,
		isTypingStarted: false
	};
    
	setText = () => {
		const texts = [
			`Haliç'i mehtapta izleyenler bilirler. Haliç, mehtapta sihirli bir doğa parçasıdır. Suları, altın akan bir nehre benzer. Bir bakarsın kıyının birinde yangına dönüşür. Bir bakarsın, gümüş bir hançer olmuş. Köprüde balık tutanlar, bu gümüş hançeri ele geçirmeye çalışan masal yaratıklarıdır. Gözlerinize olan inancınız zayıflar, gönül gözüyle gördüğünüzü sanırsınız; çünkü gözlerinizi kapayınca bu görkemli manzara kaybolup gitmez. Renk ve şekil değiştirerek sürüp gider.`,
			`Bir yağmur, efendim, sormayın! Öyle bardaklardan, testilerden boşanırcasına değil; kazanlardan, sarnıçlardan boşanırcasına bir yağmur. Hızlı yağmasına bakıyorsunuz da "Eh, yaz yağmurudur, şimdi başlar, şimdi geçer!" diyorsunuz. O, sizin dediğiniz eski zaman yağmurları, bizim çocukluğumuzda yağardı, şimdikiler öyle değil, bir tutturdu mu, Allah vermesin! Dinmek nedir bilmiyor, geceli gündüzlü yağıyor.`,
			`Çirkindir kış, kısa günlüdür. Kışın günler o kadar kısadır ki gezip tozmaya bile vakit bulamazsınız. İnsanlar sevimsizdir. Gülmezler ve kışın hikaye anlatmazlar. Hele Kordonboyu'nda deniz hep çalkantılıdır. Lodos dalgalarla gelir, Kordon'a taşar. Yağmur yağar ve damlarda gözenekleri yemyeşil, tüyümsü kiremit otları biter. Yağmur yağar ve camlarda kışın ağlamaklı izleri kalır.`,
			`Dil, insanların düşünürlüğünden doğmuş; doğduktan sonra da düşüncelerin yaratıcısı olmuştur. Bilgilerimizi, öğrenimimizi, başkalarının düşüncelerini dil yoluyla elde ederiz. Kendi düşüncelerimiz de kafamızın içinde çalışan dilin yardımıyla oluşur, olgunlaşır. Sanki içimizden konuşarak, sessiz bir dil kullanarak düşünür, yeni yeni düşüncelere ulaşırız. Ulaştığımız yeni düşünceleri başkalarına ulaştırırız. Böylece, sürekli olarak düşünce dili, dil de düşünceyi geliştirir durur.`,
			`Folklorun varlığı, tarih ötesine gidecek kadar eskidir; bir bilim oluşu ise yüzyılı aşar. Önceleri, folklor, halk gelenekleri ile göreneklerinin, halk inanışlarının bilimi diye düşünülüyordu. Halk masalları, hikâyeleri, şiirleri, bir kelime ile halk edebiyatı da onun konuları arasında idi. Fakat geçen her gün folklorun uğraştığı alanları genişlettikçe genişletti; halk yaşamı ile ilgili her olay bir folklor maddesi haline geldi. Bunun için, folklorun konusunu kesin çizgilerle sınırlandırmak kolay değildir.`,
			`Ayrıkotu, bakımlı bahçelerin düşmanıdır. Önce küçücük bir parçası düşer toprağa. Güneş altında kurur ve dikkati çekmez olur. Toprağa değdiği yerden kök salar derinlere. Bir gün bir yerden yemyeşil fışkırır. Bahçıvan bunu görür ve koparıp atar. O, on yerde birden fışkırır. Bahçe hapı yutmuştur artık. Kanser de böyle.`,
			`Bir yağmur, efendim, sormayın! Öyle bardaklardan, testilerden boşanırcasına değil; kazanlardan, sarnıçlardan boşanırcasına bir yağmur. Hızlı yağmasına bakıyorsunuz da “Eh, yaz yağmurudur, şimdi başlar, şimdi geçer!” diyorsunuz. O, sizin dediğiniz eski zaman yağmurları, bizim çocukluğumuzda yağardı, şimdikiler öyle değil, bir tutturdu mu, Allah vermesin! Dinmek nedir bilmiyor, geceli gündüzlü yağıyor.`,
			`Gerçek sanatçılar, okunmak için toplumun hoşuna gitmeye çalışmaz. Tam tersine sarsar toplumu. Alıştığı, sevdiği, kendi çıkarına olan şeyleri sunmaz topluma. Alışmadıklarını, o güne değin hiç düşünmediklerini, nefret ettiklerini, kendinden başka herkesi düşünenleri anlatarak rahatsız eder okuyucusunu. Yepyeni şeyler sunar, ufkunu açar okurun; ama bu arada onu kızdırır. Yüzyıl sonrasının dünyasında neler olacak onu anlatmaya çalışır okurlarının. Yani bir Dostoyevski, bir Stendhal olur. Sanatçının sahtesi ise uyutur okurunu, rahatladır, gevşetir. Tabii sonra da silinir gider.`,
			`Bilim, özgür bir ortamda filizlenir. Ama bu sadece bir filizlenmedir. Büyüyüp gelişmesi, ekonomik koşullara bağlıdır. Bilimsel çalışmalara yeterince kaynak ayırmayan ülkelerde bilim filizi ya kurur ya da cılız kalır. Bu tutum, bir iyi niyetten öte gidemez.`,
			`Edebiyat; hava gibi, su gibi, güneş gibi, toprak gibi vazgeçilmezdi. Onunla yatılıp onunla kalkılıyordu ve yaratıcı gücünün sonsuzluğuna, edebiyatın insanı insan yapma büyüsüne İnanılıyordu. Toplumun yozlaşmaya ve her şeyin parayla ölçüldüğü, bilgi ve kültüre duyulan saygının, kredi kartlarına, görselliğe yönelmeye başladığı yıllarda, edebiyat “Bir işlevi yok.” düşüncesiyle gazetelerden kovuldu. Edebiyat kovulunca da gazeteler çirkinleşti, gazetelerle birlikte dil de espri de düşünce de sığlaşıp yüceliğini yitirdi.`,
			`Kendimi çok az döküp saçıyorum. Normal, gündelik bir hayat sürdürüyorum; bu da bana yetiyor. Barlarda tartışmak, günde elli kişiyle görüşüp konuşmak bana göre değil. Sürekli olarak okuyup yazıyorum. Çok titiz, çok korunaklı yaşıyorum. Alışık olmadığım insanlarla görüşmek beni rahatsız ediyor. Hayran ilişkisi bumerang gibidir; her an nefrete dönüşebilir; budar, ehlileştirir insanı.`,
			`Düş, doğanın veya yaşamın değil, bütünüyle insan beyninin yarattığı en harika eserlerden biridir. Tutarlı tutarsız davranışlarla, evrende rastlanmayan konularla, akılları durduran görüntü ve serüvenlerle bezenmiştir. Zihinde oluşan bir dünyadır ve dokunamaz, avucunuza alamazsınız. Bu hakiki düşün yanında uyanık gözle ve kafayla görülenler, düşlenenler de var. Katı gerçeklerin ve koşulların sıkboğaz ettiği günlerde sığındığımız, dört elle sarıldığımız renkli, bizleri rahatlatan, avutan, uyuşturan düşler yararlı ve güzeldir.`,
			`Öykülerimdeki insanlar yürümeli, el sıkışmalı, kavgayı göze alabilmeli, yargılamalı, isyan etmeli; ama yaşamın sihirli güzelliğinden gözlerini ayırmadan yeni sevgiler ve dostluklar da edinmeli. Kendi özeleştirisini yapabilmeli, dünyaya açık olmalı ve bütün bunları okurlarım iliklerinde hissetmeli. Yaşamdan öykülerime, öykülerimden yaşama koşmalı; varlıklarına yeni güzellikler taşımalı. Yaşamın her ayrıntısıyla bütünleşmeli, yaşamın sanat ve edebiyatla yoğrulan birer temsilcisi olmalı ve insanlığa mesaj verebilmeli.`,
			`Binlerce yıldır önemli bir ticaret ve yönetim merkezi olan başkent, nedense turistik bir gezi için gelmez aklımıza. Oysa Cumhuriyet tarihinin önemli eserleri, camileri, kale içindeki tarihi evleri, eğlence için ünlü caddeleri, birbirinden popüler restoranları, parkları ve alışveriş merkezleriyle hiç de turistik açıdan hayal kırıklığı yaratacak bir şehir değil. Ayrıca çevresindeki ören yerleri Hattuşaş, Yazılıkaya, Alacahöyük ve Gordion’la da oldukça turist çekiyor. Şehre yaklaşık 100 kilometre mesafedeki Beypazarı ise son zamanlarda yaptığı atakla gezginlerin yeni duraklarından biri olmaya aday.`,
			`Tarih boyunca, aşağı yukarı her kültürde seramik sanatının, toplumsal kimlikle hayati bir bağı olmuştur. Yunancadan gelen seramik sözcüğü her biçimdeki kil anlamındadır. Bu malzemenin kalitesi sayesinde, müzelerin çoğunda, tarih boyunca sanatçıların kendilerini toprakla nasıl ifade ettiklerini görebiliriz. Seramik sanatında kullanılan teknikler, tarih öncesi dönemlerden günümüze dek, değişmeden gelmiştir. Kuşkusuz bu denli köklü bir geleneğe, zengin ifade olanaklarına ve yenilik potansiyeline sahip bir başka el sanatı daha yoktur.`,
			`Klasik roman, kendi içinde dört dörtlük bir dünyadır. Onun bu kendi kendine yeterliliği, entelektüel seviyesi birbirinden farklı kitlelerce okunabilmesine olanak verir. Oysa modern roman, klasik romanın bu kendi başı-nalığını kırar. Oradaki karakterler, olaylar ya da diyaloglar salt kendilerinden kaynaklanmaz. Modem roman, birçok farklı anlatıdan esinlenir ve oralardan alınan parçalarla kendini var eder. Modern roman, dış referanslarını değersiz olmaktan çıkarıp hayati bir öneme kavuşturur. Okurundan belli bir entelektüel yoğunluk ve zihinsel çaba ister; onda bilmece çözme ve oyun oynama isteği uyandırır.`,
			`İnsan, doğduğu saatten öleceği saate kadar severek, kavga ederek, cephelerde çarpışarak, hastalanarak yaşar. Yaşadıkları, o istese de istemese de varlığındaki derin kuyularda birikir. Birikenleri bir sonuca varmak amacıyla sıraladığında da “yaşam tarihi”nin belirdiğini görür. Bu insanlardan biri, yüreğinden yükselen fokurtularla, bu fokurtuları değerlendiren bir yeteneğin itmesiyle yazarlığa soyunursa, kuyularında birikenlere ve başkalarının biriktirdiğine uzanmak durumundadır.`
		];


		const text = texts[Math.floor(Math.random() * texts.length)];
		const words = text.split(' ');

		this.setState({
			text,
			words,
			completedWords: []
		});
	};

	startGame = () => {
		this.setText();

		this.setState({
			completed: false,
			startTime: undefined,
			timeElapsed: 0,
			wpm: 0.00,
			started: true,
			progress: 0,
			isTypingStarted: false
		});
	};

	handleChange = e => {
		const { words, completedWords } = this.state;
		const inputValue = e.target.value;
		const lastLetter = inputValue[inputValue.length - 1];

		const currentWord = words[0];

		// if space or '.', check the word
		if (lastLetter === ' ' || lastLetter === '.') {
			// check to see if it matches to the currentWord
			// trim because it has the space
			if (inputValue.trim() === currentWord) {
				// remove the word from the wordsArray
				// cleanUp the input
				const newWords = [...words.slice(1)];
				const newCompletedWords = [...completedWords, currentWord];

				// Get the total progress by checking how much words are left
				const progress =
					(newCompletedWords.length /
						(newWords.length + newCompletedWords.length)) *
					100;
				this.setState({
					words: newWords,
					completedWords: newCompletedWords,
					inputValue: '',
					completed: newWords.length === 0,
					progress
				});
			}
		} else {
			if (!this.state.isTypingStarted) {
				this.setState({
					startTime: Date.now(),
					isTypingStarted: true
				});

				// little hack to overcome time being NaN for one second
				setTimeout(() => { 
					this.calculateTimeDiff();
				}, 10);
				setInterval(() => {
					this.calculateTimeDiff();
				}, 1000);
			}

			this.setState({
				inputValue,
				lastLetter
			});
		}

		this.calculateWPM();
	};

	calculateWPM = () => {
		const { startTime, completedWords } = this.state;
		const now = Date.now();
		const diff = (now - startTime) / 1000 / 60; // 1000 ms / 60 s
		
		// every word is considered to have 5 letters
		// so here we are getting all the letters in the words and divide them by 5
		// "my" shouldn't be counted as same as "deinstitutionalization"
		const wordsTyped = Math.ceil(
			completedWords.reduce((acc, word) => (acc += word.length), 0) / 5
		);

		// calculating the wpm, if NaN put 0 instead
		let wpm = (wordsTyped / diff).toFixed(2);
		if (wpm === "NaN") { 
			wpm = 0.00;
		 }
		this.setState({
			wpm
		});
	};

    calculateTimeDiff = () => {
        const { startTime } = this.state;
        const now = Date.now();
        const diff = (now - startTime) / 1000 / 60; // 1000 ms / 60 s
        var timeElapsed = diff;

		if (startTime === undefined) {
			timeElapsed = 0
		}
		this.setState({
            timeElapsed
        });
    };

	render() {
		const {
			text,
			inputValue,
			completedWords,
			wpm,
			timeElapsed,
			started,
			completed,
			progress
		} = this.state;

		if (!started)
			return (
				<div className='container'>
					<h2>Hoş geldiniz</h2>
					<p>
						<strong>Kurallar:</strong> <br />
						Vurgulanmış kelimeyi yazınız. <br />
						Doğru yazılan kelimeler <span className='green'>yeşil</span> renk olacaktır.
						<br />
						Yanlış yazılan kelimeler ise <span className='red'>kırmızı</span> olacaktır.
						<br />
						<br />
						Her <strong>5 harf</strong> bir kelime olarak sayılır.
						<br />
						<br />
						İyi eğlenceler!
					</p>
					<button className='start-btn' onClick={this.startGame}>
						Oyunu Başlat
					</button>
                    <Footer />
				</div>
			);

		if (!text) return <p>Yükleniyor...</p>;

		if (completed) {
			return (
				<div className='container'>
					<h2>
						Dakika başına yazdığınız kelime sayısı: <strong>{wpm}</strong>
					</h2>
					<h2>
						Dakika başına yazdığınız harf sayısı: <strong>{wpm*5}</strong>
					</h2>
					<button className='start-btn' onClick={this.startGame}>
						Yeniden Başlat
					</button>
					<p>veya</p>
					<p>
						<a
							href={`https://twitter.com/intent/tweet?text=Klavyede yazma hızım dakikada ${wpm} kelime. Sen ne kadar hızlısın görelim! klavyehiztesti.com`}
							target=''
							className='share'>
							Skorunuzu Twitter'da paylaşın
						</a>
					</p>
                    <Footer />
				</div>
                
			);
		}

		return (
			<div>
				<div className='wpm'>
					<strong>Dakika başına kelime: </strong>
					{wpm}
					<br />
					<strong>Dakika başına harf: </strong>
					{(wpm*5).toFixed(2)}
					<br />
					<strong>Süre: </strong>
					{Math.floor(timeElapsed * 60)}s
				</div>
				<div className='container'>
					<h4>Aşağıdaki kelimeleri yazmaya başla</h4>
					<progress value={progress} max='100'></progress>
					<p className='text'>
						{text.split(' ').map((word, w_idx) => {
							let highlight = false;
							let currentWord = false;

							// this means that the word is completed, so turn it green
							if (completedWords.length > w_idx) {
								highlight = true;
							}

							if (completedWords.length === w_idx) {
								currentWord = true;
							}

							return (
								<span
									className={`word 
                                ${highlight && 'green'} 
                                ${currentWord && 'underline'}`}
									key={w_idx}>
									{word.split('').map((letter, l_idx) => {
										const isCurrentWord = w_idx === completedWords.length;
										const isWronglyTyped = letter !== inputValue[l_idx];
										const shouldBeHighlighted = l_idx < inputValue.length;

										return (
											<span
												className={`letter ${
													isCurrentWord && shouldBeHighlighted
														? isWronglyTyped
															? 'red'
															: 'green'
														: ''
												}`}
												key={l_idx}>
												{letter}
											</span>
										);
									})}
								</span>
							);
						})}
					</p>
					<input
						type='text'
						onChange={this.handleChange}
						value={inputValue}
						autofocus={started ? 'true' : 'false'}
					/>
				</div>
                <Footer />
			</div>
		);
	}
}

ReactDOM.render(<App />, document.getElementById("root"));
